@import "https://fonts.googleapis.com/css2?family=Cairo&family=Inter&display=auto";
:root {
  --scale: 1;
}

@font-face {
  font-family: Akkurat Mono;
  src: url("AkkuratMono-Regular.69ecc5d0.woff2") format("woff2"), url("AkkuratMono-Regular.ca1233d5.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "kern" 1;
  -ms-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  text-transform: uppercase;
  background: #000;
  margin: 0;
  font-family: Akkurat Mono;
  font-size: 14px;
  font-style: normal;
  line-height: 140%;
  overflow: hidden;
}

p {
  margin-bottom: .4rem;
}

p:last-of-type {
  margin: 0;
}

.dotted {
  border-bottom: 1px dashed;
  padding: 1rem 0;
}

a {
  color: #cdffd2;
  text-decoration: none;
}

a:hover {
  opacity: .75;
}

canvas {
  position: fixed;
  top: 0;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
}

.right {
  text-align: right;
  width: 100%;
  margin: 0 0 0 auto;
}

.arabic {
  font-family: Cairo, sans-serif;
}

:root {
  --app-height: 100%;
}

.container {
  height: 100vh;
  height: var(--app-height);
  z-index: 1;
  width: 100%;
  max-width: 100%;
  color: #cdffd2;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: scroll;
}

.top, .bottom {
  max-width: 360px;
}

.logo {
  width: 100px;
  transform-origin: 0 0;
}

@keyframes pulse {
  0% {
    opacity: .1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: .1;
  }
}

.top, .bottom {
  width: 100%;
}

.top {
  margin-bottom: 2rem;
}

.container.glow {
  opacity: 0;
  text-shadow: 0 0 8px #42d38e, 0 0 8px #42d38e;
  animation: 3s ease-in-out infinite pulse;
}

.container.glow .logo {
  position: relative;
  top: -8px;
  left: -8px;
}

.container.glow .cta {
  box-shadow: 0 0 8px #42d38e, 0 0 8px #42d38e, inset 0 0 8px #42d38e, inset 0 0 8px #42d38e;
}

.container.glow .cta svg {
  box-shadow: 0 0 8px #42d38e, 0 0 8px #42d38e;
}

.description {
  letter-spacing: -.01em;
  text-transform: uppercase;
}

a.cta {
  letter-spacing: -.01em;
  text-transform: uppercase;
  height: 55px;
  width: 105%;
  max-width: 105%;
  text-align: center;
  border: 2px solid;
  border-radius: 27px;
  margin-bottom: 20px;
  margin-left: -2.5%;
  line-height: 50px;
  display: block;
}

a.cta svg {
  fill: currentColor;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.footer {
  width: 500px;
  max-width: 100%;
  text-transform: uppercase;
  margin-top: 1.5rem;
  line-height: 15px;
}

/*# sourceMappingURL=index.6134cc14.css.map */
