@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Inter&display=auto');
$breakpoint-mobile: 360px;
$block-width: 500px;
$container-padding: 20px;

:root {
  --scale: 1;
}

@font-face {
  font-family: 'Akkurat Mono';
  src: url('static/AkkuratMono-Regular.woff2') format('woff2'),
    url('static/AkkuratMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  width: 100%;
  overflow: hidden;
  font-family: 'Akkurat Mono';
  font-style: normal;
  font-size: 14px;
  line-height: 140%;
  background: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -moz-font-feature-settings: 'kern' 1;
  -ms-font-feature-settings: 'kern' 1;
  -o-font-feature-settings: 'kern' 1;
  -webkit-font-feature-settings: 'kern' 1;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  text-transform: uppercase;
}

p {
  margin-bottom: 0.4rem;

  &:last-of-type {
    margin: 0;
  }
}

.dotted {
  padding: 1rem 0;
  border-bottom: 1px dashed;
}

a {
  color: #cdffd2;
  text-decoration: none;
}

a:hover {
  opacity: 0.75;
}

canvas {
  position: fixed;
  top: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body {
}

.right {
  text-align: right;
  width: 100%;
  margin: 0 0 0 auto;
}

.arabic {
  font-family: 'Cairo', sans-serif;
}

:root {
  --app-height: 100%;
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;

  justify-content: space-between;

  height: 100vh;
  height: var(--app-height);
  z-index: 1;
  width: 100%;
  max-width: 100%;
  padding: $container-padding;
  overflow: scroll;

  color: #cdffd2;
}

.top,
.bottom {
  max-width: $breakpoint-mobile;
}

.logo {
  width: 100px;
  transform-origin: top left;
  // transform: scale(0.25);
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}

.top,
.bottom {
  width: 100%;
}

.top {
  margin-bottom: 2rem;
}

.container.glow {
  opacity: 0;
  animation: pulse 3s infinite ease-in-out;
  text-shadow: 0px 0px 8px #42d38e, 0px 0px 8px #42d38e;
  .logo {
    position: relative;
    left: -8px;
    top: -8px;
  }
  .cta {
    box-shadow: 0px 0px 8px #42d38e, 0px 0px 8px #42d38e,
      inset 0px 0px 8px #42d38e, inset 0px 0px 8px #42d38e;
  }
  .cta svg {
    box-shadow: 0px 0px 8px #42d38e, 0px 0px 8px #42d38e;
  }
}

.description {
  letter-spacing: -0.01em;
  text-transform: uppercase;
  // width: $block-width;
  // max-width: 100%;
}

a.cta {
  display: block;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  border: 2px solid currentColor;
  height: 55px;
  line-height: 50px;
  border-radius: 27px;
  width: 105%;
  max-width: 105%;
  text-align: center;
  margin-bottom: $container-padding;
  margin-left: -2.5%;

  svg {
    fill: currentColor;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
}

.footer {
  width: $block-width;
  max-width: 100%;
  text-transform: uppercase;
  line-height: 15px;
  margin-top: 1.5rem;
}
